<template>
  <GreenCirclePreloader v-if="showPreloader" />
  <div v-else class="upload-section">
    <UploadFileComponent class="input-wrapper" @download-link="handleFileUpload" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useOrderInfoStore } from "@/stores/orderInfoStore";
import { storeToRefs } from "pinia";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";
import UploadFileComponent from "@/components/UploadFileComponent";
import { uploadService } from "@/services/uploadService";

const { orderInfo } = storeToRefs(useOrderInfoStore());

const textractResultsLoading = ref(false);
const ocrResults = ref(null);
const showPreloader = ref(false);

const handleFileUpload = (downloadLinkInfo) => {
  showPreloader.value = true;
  textractPatientInfo(downloadLinkInfo.uploadKey);
  showPreloader.value = false;
};

const fieldsMapping = {
  first_name: "patientInfo.firstName",
  last_name: "patientInfo.lastName",
  dob: "patientInfo.dob",
  insurance_id: "patientInfo.insuranceId",
  middle_name: "patientInfo.middleName",
  phone: "patientInfo.phone",
  gender: "patientInfo.gender",
  address1: "patientInfo.address1",
  city: "patientInfo.city",
  zip: "patientInfo.zip",
  provider_first_name: "providerInfo.providerFirstName",
  provider_last_name: "providerInfo.providerLastName",
  provider_title: "providerInfo.providerTitle",
  contact_name: "providerInfo.contactName",
  contact_phone: "providerInfo.contactPhone",
  contact_fax: "providerInfo.contactFax",
  npi_number: "providerInfo.npiNumber",
  tin: "providerInfo.tin",
  specialty: "providerInfo.specialty",
  office_name: "providerInfo.officeName",
  email: "providerInfo.email",
  provider_address1: "providerInfo.providerAddress1",
  provider_city: "providerInfo.providerCity",
  provider_state: "providerInfo.providerState",
  provider_zip: "providerInfo.providerZip",
  start_date: "startDate",
  frequency: "frequency",
  expected_length_of_therapy: "expectedLengthOfTherapy",
  quantity_per30_days: "quantityPer30Days",
};

async function textractPatientInfo(uploadKey) {
  textractResultsLoading.value = true;
  const response = await uploadService.startTextractForUploadedFileURL(uploadKey);
  if (response.data && response.data.query_results) {
    ocrResults.value = response.data.query_results;
    Object.entries(ocrResults.value).forEach(([ocrKey, ocrValue]) => {
      if (fieldsMapping[ocrKey]) {
        const valuePath = fieldsMapping[ocrKey].split(".");
        if (valuePath.length === 1) {
          orderInfo.value[valuePath[0]] = ocrValue;
        } else if (valuePath.length === 2) {
          orderInfo.value[valuePath[0]][valuePath[1]] = ocrValue;
        }
      }
    });
  }
  textractResultsLoading.value = false;
}
</script>

<style lang="scss" scoped>
@import "../styles/components/_upload-file-component.scss";
</style>
