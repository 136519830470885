import { defineStore } from "pinia";
import { ref } from "vue";

export const useOrderInfoStore = defineStore("order-info", () => {
  const orderInfo = ref({
    patientInfo: {
      id: null,
      prefix: null,
      firstName: null,
      middleName: null,
      lastName: null,
      suffix: null,
      dob: null,
      insuranceId: null,
      phone: null,
      gender: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      weight: null,
      height: null,
      drugAllergies: null,
    },
    providerInfo: {
      id: null,
      providerFirstName: null,
      providerLastName: null,
      providerTitle: null,
      contactName: null,
      contactPhone: null,
      contactFax: null,
      npiNumber: null,
      tin: null,
      specialty: null,
      officeName: null,
      officePhone: null,
      fax: null,
      email: null,
      providerAddress1: null,
      providerAddress2: null,
      providerCity: null,
      providerState: null,
      providerZip: null,
    },
    drugsTried: [
      {
        drugTried: null,
        startDate: null,
        endDate: null,
        resultOfTrial: null,
      },
      {
        drugTried: null,
        startDate: null,
        endDate: null,
        resultOfTrial: null,
      },
      {
        drugTried: null,
        startDate: null,
        endDate: null,
        resultOfTrial: null,
      },
      {
        drugTried: null,
        startDate: null,
        endDate: null,
        resultOfTrial: null,
      },
    ],
    primaryDiagnosis: {
      diagnosis: null,
      icd10Code: null,
    },
    otherDiagnosis: [
      {
        diagnosis: null,
        icd10Code: null,
      },
      {
        diagnosis: null,
        icd10Code: null,
      },
      {
        diagnosis: null,
        icd10Code: null,
      },
      {
        diagnosis: null,
        icd10Code: null,
      },
      {
        diagnosis: null,
        icd10Code: null,
      },
      {
        diagnosis: null,
        icd10Code: null,
      },
    ],
    medication: null,
    strength: null,
    routeAdministration: null,
    startDate: null,
    frequency: null,
    expectedLengthOfTherapy: null,
    quantityPer30Days: null,
    expeditedReviewCheck: null,
    expeditedReviewStatement: null,
    expeditedReviewWithin24h: null,
    pharmacyInfo: null,
    checklist: [],
    checklistLlmScores: [],
    insuranceProvider: null,
    insurancePlanType: null,
    benefitType: null,
    medicalNecessity: null,
    labResults: [],
    patientChart: [],
    guidelines: [],
    placeOfAdministration: null,
    placeOfAdministrationDetails: {
      name: null,
      phone: null,
    },
    administrationCodesCpt: null,
    placeOfAdministrationAddress: null,
    dispensingProviderInfo: {
      id: null,
      type: null,
      name: null,
      phone: null,
      fax: null,
      address: null,
      tin: null,
      pin: null,
    },
    orderKey: null,
    formStep: null,
    stepTherapyRequired: [],
    payerQuestions: [],
    medicalNecessityTemplate: null,
    signature: null,
    stageOfTherapy: null,
  });

  return {
    orderInfo,
  };
});
