<template>
  <GreenCirclePreloader v-if="showPreloader" />
  <div v-else class="upload-section">
    <input ref="pdfInput" type="file" accept="image/png, image/jpeg" @change="handleFileUpload" />
  </div>
</template>

<script setup>
import { createWorker } from "tesseract.js";
import { ref } from "vue";
import { useOrderInfoStore } from "@/stores/orderInfoStore";
import { storeToRefs } from "pinia";
import { convertDateToYYYYMMDD } from "@/utils";
import { usaStates } from "@/utils/usaStates";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";

const { orderInfo } = storeToRefs(useOrderInfoStore());

const pdfInput = ref(null);
const ocrResults = ref(null);
const showPreloader = ref(false);

const allowedFileTypes = ["image/png", "image/jpeg"];

const handleFileUpload = async (event) => {
  showPreloader.value = true;
  const file = event.target.files[0];
  if (!file) return;
  if (!allowedFileTypes.includes(file.type)) return;
  if (file.size > 10000000) return;
  await performOCR(file);
  showPreloader.value = false;
};

const fieldMapping = {
  gender: ["sex", "gender"],
  dob: ["dob", "dateofbirth", "birthdate", "birthday"],
  insuranceId: [
    "memberid",
    "patientinsurancenumber",
    "patientidnumber",
    "insuranceid",
    "patientinsuranceid",
    "membernumber",
  ],
  zip: ["zipcode", "zip", "patientzipcode", "patientzip"],
  address1: [
    "address",
    "address1",
    "patientaddress",
    "patientaddress1",
    "patientstreetaddress",
    "streetaddress",
    "street",
  ],
  address2: ["address2", "patientaddress2", "patientapt", "apt", "patientapartment", "apartment"],
};

function mapOcrKeyToOrderInfo(ocrResultsInitial) {
  // get keys of patientInfo from orderInfo
  const patientInfoKeys = Object.keys(orderInfo.value.patientInfo);
  ocrResults.value = ocrResultsInitial.reduce((acc, cur) => {
    if (!cur) return acc;
    acc[cur.key] = cur.value;
    return acc;
  }, {});
  const ocrResultsKeys = Object.keys(ocrResults.value);
  // filter out ocrResultsKeys that contain "patient" in the key
  const ocrResultsKeysFiltered = ocrResultsKeys.filter((key) => key.includes("patient"));
  // map ocrResultsKeysFiltered to patientInfoKeys if they are the similar using fuzzy matching
  ocrResultsKeysFiltered.map((ocrKey) => {
    const patientInfoKey = patientInfoKeys.find((patientKey) => {
      const patientKeyLower = patientKey.toLowerCase();
      // replace patient with empty string, remove whitespace and colon
      const ocrKeyLower = ocrKey.replace("patient", "");
      const fieldMappingValues = fieldMapping[patientKey];
      if (fieldMappingValues) {
        return (
          patientKeyLower.includes(ocrKeyLower) ||
          ocrKeyLower.includes(patientKeyLower) ||
          fieldMappingValues.includes(ocrKeyLower)
        );
      } else return patientKeyLower.includes(ocrKeyLower) || ocrKeyLower.includes(patientKeyLower);
    });
    if (patientInfoKey) {
      if (orderInfo.value.patientInfo[patientInfoKey]) return;
      alignFieldFormats(patientInfoKey, ocrKey);
      orderInfo.value.patientInfo[patientInfoKey] = ocrResults.value[ocrKey] ? ocrResults.value[ocrKey] : null;
    }
  });
  ocrResultsKeys.map((ocrKey) => {
    const patientInfoKey = Object.keys(fieldMapping).find((key) => {
      const fieldMappingValues = fieldMapping[key];
      return fieldMappingValues.includes(ocrKey);
    });
    if (patientInfoKey) {
      if (orderInfo.value.patientInfo[patientInfoKey]) return;
      alignFieldFormats(patientInfoKey, ocrKey);
      orderInfo.value.patientInfo[patientInfoKey] = ocrResults.value[ocrKey] ? ocrResults.value[ocrKey] : null;
    }
  });
  ocrResultsKeys.map((ocrKey) => {
    const patientInfoKey = patientInfoKeys.find((patientKey) => {
      const patientKeyLower = patientKey.toLowerCase();
      const fieldMappingValues = fieldMapping[patientKey];
      if (fieldMappingValues) {
        return (
          patientKeyLower.includes(ocrKey) || ocrKey.includes(patientKeyLower) || fieldMappingValues.includes(ocrKey)
        );
      } else return patientKeyLower.includes(ocrKey) || ocrKey.includes(patientKeyLower);
    });
    if (patientInfoKey) {
      if (orderInfo.value.patientInfo[patientInfoKey]) return;
      alignFieldFormats(patientInfoKey, ocrKey);
      orderInfo.value.patientInfo[patientInfoKey] = ocrResults.value[ocrKey] ? ocrResults.value[ocrKey] : null;
    }
  });
}

function alignFieldFormats(patientInfoKey, ocrKey) {
  if (ocrResults.value[ocrKey] && ocrResults.value[ocrKey].length) {
    switch (patientInfoKey) {
      case "gender":
        if (ocrResults.value[ocrKey].length === 1) {
          ocrResults.value[ocrKey] = ocrResults.value[ocrKey].toUpperCase() === "M" ? "Male" : "F" ? "Female" : null;
        } else {
          const genderString = ocrResults.value[ocrKey].toLowerCase().replace(/\s/g, "");
          if (genderString.includes("[x]")) {
            const patientGender = genderString.split("[x]")[1];
            ocrResults.value[ocrKey] = patientGender[0] === "m" ? "Male" : patientGender[0] === "f" ? "Female" : null;
          }
        }
        break;
      case "dob":
        ocrResults.value[ocrKey] = convertDateToYYYYMMDD(ocrResults.value[ocrKey]);
        break;
      case "state":
        if (ocrResults.value[ocrKey].length === 2) {
          const stateAbbreviation = ocrResults.value[ocrKey];
          const stateName = Object.values(usaStates).find((state) => state.abbreviation === stateAbbreviation).name;
          ocrResults.value[ocrKey] = stateName;
        }
        break;
      default:
        break;
    }
  }
}

function extractKeyValuePairsFromOCRResults(ocrResultsArray) {
  const ocrResultsArrayFiltered = ocrResultsArray.filter((item) => item !== "");
  const ocrResultsArrayFilteredWithKey = ocrResultsArrayFiltered.map((item) => {
    const itemArray = item.text.split(":");
    const key = itemArray[0].toLowerCase().replace(/\s/g, "");
    if (itemArray.length === 1) return;
    const value = itemArray[1].replace(/\n/g, "").trim();
    return { key, value };
  });
  return ocrResultsArrayFilteredWithKey;
}

async function performOCR(file) {
  const worker = await createWorker("eng");
  const ret = await worker.recognize(file);
  const ocrResultsInitial = extractKeyValuePairsFromOCRResults(ret.data.lines);
  mapOcrKeyToOrderInfo(ocrResultsInitial);
  orderInfo.value.patientInfo.id = null;
  await worker.terminate();
}
</script>

<style lang="scss" scoped>
@import "../styles/components/_upload-file-component.scss";
</style>
